.tagSet {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  input[type="radio"],
  input[type="checkbox"] {
    appearance: none;

    &:checked ~ * {
      background-color: var(--primary-color);
      color: white;
      border: 1px solid white;
    }
  }

  label {
    padding: 0.5rem 0;

    span {
      display: inline-block;
      color: white;
      background-color: var(--background-card);
      border: 1px solid var(--background-popover);

      border-radius: 1rem;
      padding: 0.25rem 1rem;
    }
  }
}
