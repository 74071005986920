.formGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem;
  margin-bottom: 1rem;

  label {
    font-size: 0.75rem;
    padding-left: 0.5rem;
    margin-bottom: 0.15rem;
    color: var(--primary-color);
  }
}
