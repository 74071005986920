.card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--background-popover);
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
}

.card h3 {
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 0.25rem 0;
}
