.batchDetails {
  padding-bottom: 1rem;

  :global(.p-button-info) {
    font-size: 0.8rem;
  }
}

.batchInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.batchStats {
  flex: 1 1 auto;
  margin-left: 1rem;
}

.description {
  text-align: left;
}

.tobaccos,
.flavorings {
  margin-bottom: 1rem;
}

.review {
  border-top: 1px solid var(--background-popover);
  margin-bottom: 1rem;
}

.buttonBar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 1rem;
}

.label {
  position: relative;
  aspect-ratio: 1/1;
}

.rating {
  font-size: 1rem;
  white-space: nowrap;
}

.stats {
  margin-bottom: 1rem;
}
