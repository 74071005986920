.blendDetail {
  padding-bottom: 1rem;
}

.blendInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.label {
  position: relative;
  aspect-ratio: 1/1;
  width: 50%;
}

.blendStats {
  flex: 1 1 auto;
  margin-left: 1rem;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.description {
  text-align: left;
}

.cut {
  justify-content: flex-start;
  font-size: 1.2rem;
}

.tobaccos,
.flavorings {
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.flavorings span {
  justify-content: center;
  font-size: 0.8rem;
}

.review {
  border-top: 1px solid var(--background-popover);
  margin-bottom: 1rem;
}

.buttonBar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.buttonBar button {
  margin: 0.25rem;
}

.batchesHeader {
  color: var(--primary-color);
  margin-top: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.batchCard {
  margin-bottom: 1rem;
}

.pipePhoto {
  margin: 0;
  width: 90px;
  height: 60x;

  img {
    border-radius: 40%;
  }
}

.body {
  max-width: 60%;
  font-size: 75%;
}
