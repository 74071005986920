.pipeTemplate {
  display: flex;
  gap: 1rem;

  align-items: center;
}

.pipePhoto {
  margin: 0;
  width: 45px;
  height: 40x;

  img {
    border-radius: 40%;
  }
}
