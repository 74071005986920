.info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding-left: 1rem;
  width: 100%;
}

.disposition {
  margin-top: 0.25rem;
}

.card {
  padding: 1rem;
}

.label {
  position: relative;
  aspect-ratio: 1/1;
  width: 96px;
}
