.review {
  /* border-bottom: 1px solid var(--background-popover); */
  padding: 0.5rem 0;

  p {
    margin: 0;
  }
}

.rating {
  display: flex;
  margin-bottom: 0.5rem;
  gap: 1rem;

  :global(.p-tag-value) + span {
    padding: 0 1rem 0 1rem;
  }
}

.cigar {
  margin-bottom: 1rem;
}

.body {
  margin-bottom: 1rem;
  line-height: 150%;
}

.tagBar {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  :global(.p-tag-value) + span {
    padding: 0 1rem 0 1rem;
    white-space: nowrap;
  }
}
