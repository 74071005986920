.info {
  h2 {
    font-size: 1.2rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  margin-bottom: 0.5rem;
  width: 100%;
}

.image {
  margin: 0.5rem 0 0.75rem 0;
}

.unrated {
  font-size: 0.8rem;
  opacity: 0.5;
}

.buyAgain {
  font-family: sans-serif;
}

.footer {
  > div {
    flex: 1;
  }

  .flags {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
  }

  .rating {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;

    .average {
      margin-top: -3px;
    }
  }

  :global(.p-tag) span:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.25rem;

    svg {
      margin-right: 0.25rem;
    }
  }
}
