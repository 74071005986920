.photo {
  margin: 0 1rem 0 0;
  width: 90px;
  height: 60ps;
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;
}
