.photo {
  margin-bottom: 1rem;
}

.info > div {
  margin-bottom: 0.25rem;
}

.markings {
  font-size: 0.75rem;
}

.grid4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.buttonBar {
  margin-bottom: 1rem;
}
