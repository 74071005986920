.cigarReviewForm {
  label {
    color: var(--primary-color);
    font-size: 0.85rem;
  }

  .rating {
    margin-bottom: 1rem;
  }
}
.ratings {
  margin: 0 0 2rem 0;
}
