.batchEditForm {
  position: relative;

  input[id="name"],
  input[id="maker"],
  textarea[id="description"] {
    width: 100%;
  }

  [id="blendSlug"] {
    width: 100%;
  }

  [id="seller"] {
    width: 100%;
  }

  [id="weight"] input {
    width: 6rem;
  }

  [id="price"] input {
    width: 6rem;
  }

  [id="note"] {
    width: 100%;
  }
}
