.blendEditForm {
  position: relative;

  input[id="name"],
  input[id="maker"],
  textarea[id="description"] {
    width: 100%;
  }

  :global(.p-rating) :global(.p-rating-item) :global(.p-rating-icon) {
    font-size: 1.5rem;
  }
}

.ratings label {
  margin-bottom: 0.25rem;
}
