.rating {
  display: flex;
  flex-wrap: nowrap;
}

.set {
  color: #e9ce68;
}

.unrated {
  opacity: 0.5;
  min-width: 5rem;
  padding-left: 0.75rem;
}
