.toolControl {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.trigger {
  fill: var(--primary-color);
  fill-rule: nonzero;
}
