.calendarSheet {
  border: 3px solid var(--primary-color);
  border-radius: 0.5rem;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-width: 4.5rem;

  .year {
    font-size: 0.75rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: var(--background-dark);
    background-color: var(--primary-color);
    padding: 0.25rem 0;
    width: 100%;
    text-align: center;
    font-weight: bolder;
  }

  .month {
    font-size: 0.75rem;
    color: var(--primary-color);
    text-align: center;
    padding: 0.25rem 0;
  }

  .date {
    color: var(--primary-color);
    font-size: 1.35rem;
    text-align: center;
    width: 4rem;
  }
}
