.container {
  position: relative;
  width: 100%;
}

.image {
  border-radius: 0.5rem;
}

.cover {
  object-fit: "cover";
}
